<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="">
          <div class="intro-banner">
            <img src="@/assets/images/index/banner.png" />
            <div class="banner-text">
              <a href="/index">首页</a>
              <span>></span>
              <a href="javascript:void(0);">对外服务</a>
            </div>
          </div>
          <div class="news-left pt-5 white-bg" style="float: unset">
<!--            <div class="left-top">-->
<!--              <h3 class="news-title" style="color: #074d8c">关于我们</h3>-->
<!--            </div>-->
            <div class="detail-contanier">
              <div class="detail-box border-0" v-html="commonInfo.commonInfo">
<!--                <p>-->
<!--                  四川省人事人才考试测评基地为四川省人力资源和社会保障厅直属正处级自收自支事业单位。位于四川军转大厦，设有功能齐全的办事大厅、20个能同时容纳600人进行集中或分散培训、纸笔考试和计算机考试为一体的多功能标准化教室、两个小型会议室和一个配有会议发言系统等先进会议系统的大型多功能会议室、监控严密的试卷保密存放室、整齐划一的阅卷登分室、机房及机房总控室，以及集考场监控巡查、试卷跟踪和卷库自动侦测、指挥调度和数据集中管理和音视频会议等功能的四川省省级人事考试指挥中心。总建筑面积4400平方米。整个基地基础设施完善，技术实力强大，能贴近市场全方位、多功能服务。-->
<!--                </p>-->

<!--                <p>-->
<!--                  主要任务为：承担四川省省直属公务员录用考试、事业单位招聘工作人员考试、各类全国、全省统一的专业技术人员资格、执业（职业）资格的考试的具体考务组织工作；负责考试信息的发布、考试成绩的公布查询、考试合格证书信息的发布等数据管理工作；承担公务员录用考试、事业单位招聘工作人员考试、部分全省统一的专业技术人员资格、执业（职业）资格的考试、社会化委托考试的网上报名工作；管理和维护“四川省人事考试基地网”。-->
<!--                </p>-->

<!--                <p>设4个内设机构：综合部、考务部、开发部、技术部。</p>-->

<!--                <div class="d-flex mb-2">-->
<!--                  <div class="intro-tag">综合部</div>-->
<!--                  <div>-->
<!--                    负责基地人事、文秘等办公行政事务；组织制定基地内部各项管理制度并监督执行；负责财务管理工作等；负责咨询热线的接-->
<!--                    听，接受相关考试业务的咨询；发放省直考点各类考试证书、成绩单。-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="d-flex mb-2">-->
<!--                  <div class="intro-tag">考务部</div>-->
<!--                  <div>-->
<!--                    负责所有考试的考务组织、安排、管理工作；负责考试发票的打印、发放工作。。-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="d-flex mb-2">-->
<!--                  <div class="intro-tag">开发部</div>-->
<!--                  <div>-->
<!--                    负责基地的信息化工作；负责各类机考的承接、技术支持、组织、实施工作和各类考试有关数据的处理；负责四川省人事考试-->
<!--                    基地和四川省人事考试基地网的建设、更新与维护；负责基地计算机设备的配置计划、维护与技术支持等工作。联系电话：-->
<!--                    028-83564040、 86514038（机考类）-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="d-flex">-->
<!--                  <div class="intro-tag">技术部</div>-->
<!--                  <div>-->
<!--                    承接行政机关领导干部公开选拔、竞争上岗和人员分流等各类考试以及事（企）业单位公开招聘、竞聘、人才选拔、部门考核-->
<!--                    等社会化委托测评业务；负责相关专家数据库和题库的建立、保管工作，承担人事人才评价制度改革的研究，开展人事人才评-->
<!--                    价新项目的研发。联系电话：028-84590599（纸笔类）-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./index-header.vue";
import Footer from "./index-footer.vue";
import Navbar from "./navbar.vue";

import { getCommonInfo } from "@/api/web/web.js";
export default {
  name: "index",
  data() {
    return {
      commondId: '6C185FAF9C861783',
      commonInfo : {}
    };
  },
  components: {
    Header,
    Navbar,
    Footer,
  },
  methods: {
    /**
     * 获取介绍信息
     */
    getCommonInfo() {
      getCommonInfo(this.commondId).then((res) => {
        if (res.status) {
          this.commonInfo = res.data
        }
      });
    }
  },
  mounted() {
    this.getCommonInfo()
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/index.css";
</style>
<style type="text/css" scoped>
.detail-contanier p {
  text-indent: 2rem;
}
</style>
