// 用户端门户相关api
import { getNoAuthRequest } from "@/libs/axios";

// 首页聚合接口
export const getIndex = params => {
    return getNoAuthRequest("/yethan/web/index", params);
};

// 获取新闻
export const getNewsListPage = params => {
    return getNoAuthRequest("/yethan/web/news/listPage", params, false);
};

// 新闻小类
export const getNewsSmallTypeList = params => {
    return getNoAuthRequest("/yethan/web/news/smallTypeList", params);
};

// 新闻详情
export function newsDetail(newsId) {
    return getNoAuthRequest("/yethan/web/news/"+newsId ,'',false);
}

// 更新浏览量
export function newsViewCount(newsId) {
    return  getNoAuthRequest("/yethan/web/news/viewCount/"+newsId, )
}


// 获取banner
export const getBanner = params => {
    return getNoAuthRequest("/yethan/web/banner", params);
};

// 获取考试报名数据
export const getExam = params => {
    return getNoAuthRequest("/yethan/web/exam/list", params);
};

// 获取考试二级分类
export const getSecondList = params => {
    return getNoAuthRequest("/yethan/web/exam/secondList", params);
};

// 获取公共信息
export function getCommonInfo(commonId) {
    return getNoAuthRequest("/yethan/web/common/"+commonId ,'',false);
}

// 获取公共信息
export function getCommonInfoByJsonpName(jsonpName) {
    return getNoAuthRequest("/yethan/web/common/jsonp/"+jsonpName ,'',false);
}